/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    IAuthenticationService,
    RegisteredCallback,
    RegistrationCanceledCallback,
    RegistrationFailedCallback,
    SignedInCallback,
    SignedInCanceledCallback,
    SignedInFailedCallback,
    SignedOutCallback,
    SignedOutFailedCallback,
    UserContextChangedCallback,
    UserContextInvalidatedCallback,
    UserSessionExpiredCallback
} from "sirius-platform-support-library/shared/authentication/authentication-service.interface";
import {UserContext} from "sirius-platform-support-library/shared/authentication/user-context/user-context";
import {IEventSubscription} from "sirius-platform-support-library/shared/event-bus/event-subscription.interface";
import {ITenant} from "sirius-platform-support-library/shared/tenants/tenant.interface";
import {Authentication, AuthenticationProgressReportingOptions} from "sirius-platform-support-library/models/common";
import {IEventBus} from "sirius-platform-support-library/shared/event-bus/event-bus.interface";
import {AuthenticationEvents} from "sirius-platform-support-library/shared/authentication/events/authentication-events";
import {ITenantStoreService} from "sirius-platform-support-library/shared/tenant-store/tenant-store-service.interface";
import {
    IAuthenticationHandler,
    IAuthenticationHandlerTypeName
} from "sirius-platform-support-library/shared/authentication/handlers/authentication-handler.interface";
import {AuthenticationConstants} from "sirius-platform-support-library/shared/authentication/authentication-constants";

import {
    IRequiredClaimsValidator
} from "sirius-platform-support-library/shared/authentication/claims/validators/required-claims-validator.interface";
import {RequiredClaims} from "sirius-platform-support-library/shared/authentication/claims/required-claims.enum";
import {
    AuthenticationStateEnum
} from "sirius-platform-support-library/shared/authentication/user-context/authentication-state.enum";
import {
    UserContextChangedEvent
} from "sirius-platform-support-library/shared/authentication/events/user-context-changed-event";
import {SignedInEvent} from "sirius-platform-support-library/shared/authentication/events/signed-in-event";
import {SignedOutEvent} from "sirius-platform-support-library/shared/authentication/events/signed-out-event";
import {SessionRenewedEvent} from "sirius-platform-support-library/shared/authentication/events/session-renewed-event";
import {SessionExpiredEvent} from "sirius-platform-support-library/shared/authentication/events/session-expired-event";
import {IClaimsMapper} from "sirius-platform-support-library/shared/authentication/claims/claims-mapper.interface";
import {
    ISessionLifecycleManager,
    ISessionLifecycleManagerTypeName
} from "sirius-platform-support-library/shared/authentication/session/session-lifecycle-manager.interface";
import {
    ITypedServiceCollection
} from "sirius-platform-support-library/dependency-injection/typed/typed-service-collection.interface";
import {CurrentUserContext} from "./user-context/current-user-context";
import {BroadcasterEvent} from "sirius-platform-support-library/shared/event-bus/broadcasting/broadcaster-event";
import {
    SignedOutFailedEvent
} from "sirius-platform-support-library/shared/authentication/events/signed-out-failed-event";
import {SignedInFailedEvent} from "sirius-platform-support-library/shared/authentication/events/signed-in-failed-event";
import {AuthenticationOptions} from "sirius-platform-support-library/shared/authentication/authentication-options";
import {SignInEvent} from "sirius-platform-support-library/shared/authentication/events/sign-in.event";
import {SignOutEvent} from "sirius-platform-support-library/shared/authentication/events/sign-out.event";
import * as LocationUtils from "../../utilities/location";
import {
    IUserContextUpdater
} from "sirius-platform-support-library/shared/authentication/user-context/user-context-updater.interface";
import {
    AuthenticationClaims
} from "sirius-platform-support-library/shared/authentication/handlers/authentication-claims";
import {OptionalClaims} from "sirius-platform-support-library/shared/authentication/claims/optional-claims.enum";
import {
    RegistrationFailedEvent
} from "sirius-platform-support-library/shared/authentication/events/registration-failed-event";
import {RegisteredEvent} from "sirius-platform-support-library/shared/authentication/events/registered-event";
import {RegisterEvent} from "sirius-platform-support-library/shared/authentication/events/register.event";
import {ExtendedAuthenticationOptions} from "./extended-authentication.options";
import {
    RegistrationCanceledEvent
} from "sirius-platform-support-library/shared/authentication/events/registration-canceled-event";
import {
    RegistrationResponse
} from "sirius-platform-support-library/shared/authentication/handlers/responses/registration-response";
import {
    AuthenticationResponse
} from "sirius-platform-support-library/shared/authentication/handlers/responses/authentication-response";
import {
    SignInResponse
} from "sirius-platform-support-library/shared/authentication/handlers/responses/sign-in.response";
import {
    SignOutResponse
} from "sirius-platform-support-library/shared/authentication/handlers/responses/sign-out-response";
import {
    SignInProcessOptions
} from "sirius-platform-support-library/shared/authentication/handlers/options/sign-in-process.options";
import {
    SignOutProcessOptions
} from "sirius-platform-support-library/shared/authentication/handlers/options/sign-out-process.options";
import {
    RegistrationProcessOptions
} from "sirius-platform-support-library/shared/authentication/handlers/options/registration-process.options";
import {RoutingUtilities} from "sirius-platform-support-library/utilities/routing-utilities";
import {AuthenticationServiceTypeName} from "./authentication-service";
import {
    SignedInCanceledEvent
} from "sirius-platform-support-library/shared/authentication/events/signed-in-canceled.event";
import {v4 as uuidv4} from 'uuid';
import {
    IPlatformBrowserNavigationGateHandler
} from "../browser-events/platform/platform-browser-navigation-gate-handler.interface";
import {
    IPlatformBrowserNavigationEventsReceiver
} from "../browser-events/platform/platform-browser-navigation-events-receiver.interface";
import {UserInfo} from "sirius-platform-support-library/models/user/user-info";
import {
    UserContextInvalidatedEvent
} from "sirius-platform-support-library/shared/authentication/events/user-context-invalidated.event";
import {
    IProgressIndicatorsService
} from "sirius-platform-support-library/shared/progress-indicators/progress-indicators-service.interface";
import {VisibleProgressIndicator} from "sirius-platform-support-library/shared/progress-indicators/progress-indicator";
import {TranslationService} from "sirius-platform-support-library/shared/localization/translations/translation.service";
import {
    SupportedTranslationsLoaders
} from "sirius-platform-support-library/shared/localization/translations/loaders/translations-loaders.constants";
import {ProgressReportingOptions} from "./progress-reporting.options";
import {
    AuthenticationProgressIndicatorRole
} from "sirius-platform-support-library/shared/authentication/authentication-progress-indicator-role.enum";
import {ObjectUtility} from "sirius-platform-support-library/utilities/object-utility";
import {ProgressReportingRoleOptions} from "./progress-reporting-role.options";
import {
    IDefaultPagesService
} from "sirius-platform-support-library/shared/site/default-pages/default-pages-service.interface";
import {SessionResponse} from "sirius-platform-support-library/shared/authentication/session/session-response";

export abstract class AuthenticationServiceBase implements IAuthenticationService,
    IPlatformBrowserNavigationGateHandler,
    IPlatformBrowserNavigationEventsReceiver {
    protected static readonly SUBSCRIBER_NAME = 'AuthenticationService';
    protected static readonly ORIGINATOR_ROUTE = 'dell.sirius.authentication.originator-route';

    private static readonly BROADCASTER_ID = uuidv4().toLowerCase();

    protected readonly window: Window;
    protected readonly tenant: ITenant;
    protected readonly settings: Authentication;
    protected readonly eventBus: IEventBus;
    protected readonly tenantStore: ITenantStoreService;
    protected readonly requiredClaimsValidator: IRequiredClaimsValidator;
    protected readonly claimsMapper: IClaimsMapper;
    protected readonly userContextHolder: IUserContextUpdater;
    protected readonly progressIndicatorsService: IProgressIndicatorsService;
    protected readonly defaultPagesService: IDefaultPagesService;
    protected readonly serviceCollection: ITypedServiceCollection;

    protected readonly authenticationHandler: IAuthenticationHandler;
    protected readonly sessionLifecycleManager: ISessionLifecycleManager;

    protected readonly translationService: TranslationService;

    protected readonly signInProcessOptions?: SignInProcessOptions;
    protected readonly signOutProcessOptions?: SignOutProcessOptions;
    protected readonly registrationProcessOptions?: RegistrationProcessOptions;

    protected readonly currentUserContext: CurrentUserContext;

    protected readonly progressReportingOptions: ProgressReportingOptions;
    protected readonly progressReportingOptionsMap: Record<string, ProgressReportingRoleOptions> = {};

    protected previousUrl?: string;
    protected previousOriginUrl?: string;
    protected allowNavigation: boolean = true;
    protected hideProgressTimeoutHandler: any;
    protected visibleProgressIndicator?: VisibleProgressIndicator = undefined;

    protected constructor(
        window: Window,
        tenant: ITenant,
        eventBus: IEventBus,
        tenantStore: ITenantStoreService,
        requiredClaimsValidator: IRequiredClaimsValidator,
        claimsMapper: IClaimsMapper,
        userContextHolder: IUserContextUpdater,
        progressIndicatorsService: IProgressIndicatorsService,
        defaultPagesService: IDefaultPagesService,
        serviceCollection: ITypedServiceCollection,
    ) {
        this.window = window;
        this.tenant = tenant;
        this.eventBus = eventBus;
        this.tenantStore = tenantStore;
        this.requiredClaimsValidator = requiredClaimsValidator;
        this.claimsMapper = claimsMapper;
        this.userContextHolder = userContextHolder;
        this.progressIndicatorsService = progressIndicatorsService;
        this.defaultPagesService = defaultPagesService;
        this.serviceCollection = serviceCollection;

        this.authenticationHandler = this.getAuthenticationHandler();
        this.sessionLifecycleManager = this.getSessionLifecycleManager();

        this.settings = tenant?.getContext()?.authentication ?? {
            enabled: false
        };

        this.progressReportingOptions = this.buildProgressReportingOptions(this.settings?.options?.progressReporting);
        this.progressReportingOptionsMap = this.buildProgressReportingOptionsMap(this.progressReportingOptions);

        const localizedResources = this.settings?.options?.progressReporting?.localizedResources;
        this.translationService = new TranslationService({
            publicPath: {
                provide: () => {
                    // @ts-ignore
                    return __webpack_public_path__;
                }
            },
            type: SupportedTranslationsLoaders.REMOTE_ASSETS,
            options: {
                prependPublicPath: false,
                baseUrl: '/assets/i18n/',
                extension: '.json'
            },
            localizedResources: localizedResources
        });

        this.signInProcessOptions = this.authenticationHandler?.getSignInProcessOptions();
        this.signOutProcessOptions = this.authenticationHandler?.getSignOutProcessOptions();
        this.registrationProcessOptions = this.authenticationHandler?.getRegistrationProcessOptions();

        this.currentUserContext = CurrentUserContext.getUnauthenticatedContext();

        this.loadCurrentUserContext();

        this.bind();
    }

    public async bootstrap(): Promise<void> {
        if (!this.isAuthenticationSupported()) {
            return;
        }

        if (this.isSignOutProcessInProgress() && this.shouldClearSignoutStatusOnSignIn()) {
            this.tenantStore.remove(AuthenticationConstants.SIGN_OUT_PROCESS_FLAG_KEY);
        }

        if (!this.isAnyProcessInProgress()) {
            const previousUserContext = this.currentUserContext.clone();
            await this.silentSignIn(false);
            if (previousUserContext.isAuthenticated()) {
                await this.syncUserContext();
            }
        }
        const authenticationOptions = this.getOptions();
        let breakFlow = false;
        if (this.sessionLifecycleManager) {
            this.sessionLifecycleManager.onSessionRenewed(this, AuthenticationServiceBase.SUBSCRIBER_NAME, (session) => {
                const event = {
                    session: session
                } as SessionRenewedEvent;
                this.triggerCrossTabEvent<SessionRenewedEvent>(AuthenticationEvents.USER_SESSION_RENEWED_EVENT, event);
            });
            this.sessionLifecycleManager.onSessionExpired(this, AuthenticationServiceBase.SUBSCRIBER_NAME, async (session) => {
                let clearSession = true;

                try {
                    const previousUserContext = this.currentUserContext.clone();
                    const newSession = await this.sessionLifecycleManager.renewSession();
                    if (ObjectUtility.isDefined(newSession) &&
                        !newSession.invalid &&
                        session.profileId === newSession.profileId &&
                        newSession?.claims
                    ) {
                        await this.processUpdatedUserClaims(newSession, previousUserContext.authenticationState);
                        clearSession = false;
                    }
                } catch (e) {
                    console.debug('Failed to renew session', e);
                }

                if (clearSession) {
                    const event = {
                        session: session
                    } as SessionExpiredEvent;
                    this.triggerCrossTabEvent<SessionExpiredEvent>(AuthenticationEvents.USER_SESSION_EXPIRED_EVENT, event);
                }
            });
            if (!this.isAnyProcessInProgress() && this.isUserAuthenticated()) {
                const previousUserContext = this.currentUserContext.clone();
                try {
                    const shouldVerifyMatchingPrincipal = !this.currentUserContext.claims?.authenticatedAsImpersonator;
                    const sessionResponse = await this.sessionLifecycleManager.validateSession(this.currentUserContext.userIdentifier, shouldVerifyMatchingPrincipal);
                    if (sessionResponse?.invalid) {
                        await this.clearCurrentUserContext(previousUserContext, true);
                        breakFlow = true;
                    } else if (sessionResponse?.claims) {
                        await this.processUpdatedUserClaims(sessionResponse, previousUserContext.authenticationState);
                    }
                } catch (e) {
                    await this.clearCurrentUserContext(previousUserContext, true);
                }
            }
        }
        if (breakFlow) {
            await this.endSignInProcess(undefined, authenticationOptions);
            await this.endSignOutProcess(undefined, authenticationOptions);
            await this.endRegistrationProcess(undefined, authenticationOptions);
            return;
        }
        const currentUrl = this.window.location.pathname;
        const signInInProgress = this.isSignInProcessInProgress();
        const signOutInProgress = this.isSignOutProcessInProgress();
        const registrationInProgress = this.isRegistrationProcessInProgress();
        if (this.isAnyProcessInProgress()) {
            if (registrationInProgress) {
                if (authenticationOptions.registrationProcessIsExternal) {
                    try {
                        await this.showProgress(AuthenticationProgressIndicatorRole.REGISTER);
                        const registrationResponse = await this.authenticationHandler.processRegistrationFlow(authenticationOptions);
                        await this.processRegistration(registrationResponse, authenticationOptions);
                        return;
                    } catch (e) {
                        await this.clearCurrentUserContextAndEndRegistrationProcess(e, authenticationOptions);
                        return;
                    }
                } else if (this.registrationProcessOptions?.registerRoute?.startsWith('/')) {
                    if (this.registrationProcessOptions?.guardRegistrationRoute || this.registrationProcessOptions?.disableNavigationWhileProcessInProgress) {
                        if (!RoutingUtilities.routeEqual(currentUrl, this.registrationProcessOptions?.registerRoute, true)) {
                            this.window.history.replaceState(null, null, this.registrationProcessOptions.registerRoute);
                        }
                        if (this.registrationProcessOptions?.disableNavigationWhileProcessInProgress) {
                            this.allowNavigation = false;
                        }
                    } else if (this.registrationProcessOptions?.cancelProcessOnNavigateAway &&
                        !RoutingUtilities.routeEqual(currentUrl, this.registrationProcessOptions?.registerRoute, true)
                    ) {
                        await this.clearCurrentUserContextAndEndRegistrationProcessOnNavigateAway(undefined, authenticationOptions);
                        return;
                    }
                }
            }
            if (signInInProgress) {
                if (authenticationOptions.signInProcessIsExternal) {
                    try {
                        await this.showProgress(AuthenticationProgressIndicatorRole.SIGN_IN);
                        const authenticationResponse = await this.authenticationHandler.processSignInFlow(authenticationOptions);
                        await this.processSignIn(authenticationResponse, authenticationOptions);
                        return;
                    } catch (e) {
                        await this.clearCurrentUserContextAndEndSignInProcess(e, authenticationOptions);
                        return;
                    }
                } else if (this.signInProcessOptions?.signInRoute?.startsWith('/')) {
                    if (this.signInProcessOptions?.guardSignInRoute || this.signInProcessOptions?.disableNavigationWhileProcessInProgress) {
                        if (!RoutingUtilities.routeEqual(currentUrl, this.signInProcessOptions?.signInRoute, true)) {
                            this.window.history.replaceState(null, null, this.signInProcessOptions.signInRoute);
                        }
                        if (this.signInProcessOptions?.disableNavigationWhileProcessInProgress) {
                            this.allowNavigation = false;
                        }
                    } else if (this.signInProcessOptions?.cancelProcessOnNavigateAway &&
                        !RoutingUtilities.routeEqual(currentUrl, this.signInProcessOptions?.signInRoute, true)
                    ) {
                        await this.clearCurrentUserContextAndEndSignInProcessOnNavigateAway(undefined, authenticationOptions);
                        return;
                    }
                }
            }
            if (signOutInProgress) {
                try {
                    await this.showProgress(AuthenticationProgressIndicatorRole.SIGN_OUT);
                    const signOutResponse = await this.authenticationHandler.processSignOutFlow(authenticationOptions);
                    await this.processSignOut(signOutResponse, authenticationOptions);
                    return;
                } catch (e) {
                    await this.clearCurrentUserContextAndEndSignOutProcess(e, authenticationOptions);
                    return;
                }
            }
        } else {
            const originatorRoute = this.window.sessionStorage.getItem(AuthenticationServiceBase.ORIGINATOR_ROUTE);
            if (this.signInProcessOptions?.guardSignInRoute && !authenticationOptions.signInProcessIsExternal &&
                this.currentUserContext.authenticationState === AuthenticationStateEnum.AUTHENTICATED &&
                RoutingUtilities.routeEqual(currentUrl, this.signInProcessOptions?.signInRoute, true)) {
                this.redirectToTenantDefaultRoute(originatorRoute);
                return;
            }
            if (this.registrationProcessOptions?.guardRegistrationRoute && !authenticationOptions.registrationProcessIsExternal &&
                (this.currentUserContext.authenticationState === AuthenticationStateEnum.UNAUTHENTICATED || this.currentUserContext.authenticationState === AuthenticationStateEnum.AUTHENTICATED) &&
                RoutingUtilities.routeEqual(currentUrl, this.registrationProcessOptions?.registerRoute, true)) {
                this.redirectToTenantDefaultRoute(originatorRoute);
                return;
            }
        }
    }

    public isAuthenticationSupported(): boolean {
        return !this.defaultPagesService.isOnFullScreenErrorPage() && this.authenticationHandler && (this.settings?.enabled || false);
    }

    public isRegistrationSupported(): boolean {
        return this.registrationProcessOptions?.enabled;
    }

    public getSecurityContext(): any | undefined {
        return this.authenticationHandler.getSecurityContext();
    }

    public getUserContext(): UserContext {
        return this.currentUserContext.toUserContext();
    }

    public isUserAuthenticated(): boolean {
        return this.currentUserContext.isAuthenticated();
    }

    public isUserRegistering(): boolean {
        return this.currentUserContext.isRegistering();
    }

    public async silentSignIn(throwsException: boolean = true): Promise<void> {
        if (!this.isAuthenticationSupported() ||
            this.isSignInProcessInProgress() ||
            this.isRegistrationProcessInProgress() ||
            this.isSignOutProcessInProgress() ||
            this.currentUserContext.isAuthenticated()) {
            return;
        }
        try {
            await this.showProgress(AuthenticationProgressIndicatorRole.SILENT_SIGN_IN);
            const signInResponse = await this.authenticationHandler.silentSignIn();
            if (!signInResponse?.claims) {
                return;
            }
            const authenticationOptions: ExtendedAuthenticationOptions = {};
            await this.processSignIn(signInResponse, authenticationOptions, true);
        } catch (e) {
            console.debug(e);
            await this.hideProgress(AuthenticationProgressIndicatorRole.SILENT_SIGN_IN);
            if (throwsException) {
                throw e;
            }
        }
    }

    public async signIn(authenticationOptions?: AuthenticationOptions): Promise<void> {
        authenticationOptions = this.getOptions(authenticationOptions);
        if (!this.isAuthenticationSupported() ||
            this.isSignInProcessInProgress() || this.isSignOutProcessInProgress() ||
            !this.currentUserContext.isUnauthenticated()) {
            return;
        }
        if (this.isRegistrationProcessInProgress()) {
            await this.clearCurrentUserContextAndEndRegistrationProcess(undefined, authenticationOptions);
            this.triggerEvent<RegistrationCanceledEvent>(AuthenticationEvents.REGISTRATION_CANCELED_EVENT, {
                originatorUrl: authenticationOptions?.originatorUrl,
                reason: 'sign-in'
            });
        }
        try {
            if (this.signInProcessOptions?.externalProcess) {
                await this.showProgress(AuthenticationProgressIndicatorRole.SIGN_IN);
            }
            this.beginSignInProcess(authenticationOptions);
            const signInResponse = await this.authenticationHandler.signIn(authenticationOptions);
            this.storeOptions({
                signInProcessIsExternal: !!this.signInProcessOptions?.externalProcess,
                registrationProcessIsExternal: !!this.registrationProcessOptions?.externalProcess,
                ...(signInResponse?.options || {})
            });
            if (signInResponse?.stopped) {
                await this.endSignInProcess(undefined, authenticationOptions);
                return;
            }
            if (this.signInProcessOptions?.deferredProcess) {
                if (!this.signInProcessOptions?.externalProcess && this.signInProcessOptions?.disableNavigationWhileProcessInProgress) {
                    this.allowNavigation = false;
                }
                return;
            }
            authenticationOptions = this.buildOptions(signInResponse?.options);
            await this.processSignIn(signInResponse, authenticationOptions);
        } catch (e) {
            console.error(e);
            await this.clearCurrentUserContextAndEndSignInProcess(e, authenticationOptions);
        }
    }

    public async completeSignIn(data: any, authenticationOptions?: AuthenticationOptions): Promise<void> {
        if (!this.isAuthenticationSupported() ||
            !this.isSignInProcessInProgress() ||
            this.signInProcessOptions?.externalProcess
        ) {
            return;
        }
        authenticationOptions = this.getOptions(authenticationOptions);
        try {
            const signInResponse = await this.authenticationHandler.processSignInFlow(authenticationOptions, data);
            authenticationOptions = this.buildOptions(signInResponse?.options);
            await this.processSignIn(signInResponse, authenticationOptions);
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    public async cancelSignIn(authenticationOptions?: AuthenticationOptions): Promise<void> {
        if (!this.isAuthenticationSupported() ||
            !this.isSignInProcessInProgress() ||
            this.signInProcessOptions?.externalProcess
        ) {
            return;
        }
        authenticationOptions = this.getOptions(authenticationOptions);
        try {
            await this.clearCurrentUserContextAndEndSignInProcess(undefined, authenticationOptions, true);
            this.triggerEvent<SignedInCanceledEvent>(AuthenticationEvents.SIGNED_IN_CANCELED_EVENT, {
                originatorUrl: authenticationOptions?.originatorUrl,
                reason: 'user'
            });
            if (this.signInProcessOptions?.redirectToOriginatorUrlOnProcessCancel && authenticationOptions?.originatorUrl) {
                authenticationOptions.redirectUrl = authenticationOptions?.originatorUrl;
            }
            this.processRedirects(authenticationOptions);
        } catch (e) {
            console.error(e);
            await this.clearCurrentUserContextAndEndSignInProcess(e, authenticationOptions);
            throw e;
        }
    }

    public async signOut(authenticationOptions?: AuthenticationOptions): Promise<void> {
        authenticationOptions = this.getOptions(authenticationOptions);
        if (!this.isAuthenticationSupported() ||
            this.isSignOutProcessInProgress() ||
            this.isSignInProcessInProgress() ||
            !this.isUserAuthenticated()
        ) {
            return;
        }
        if (this.isRegistrationProcessInProgress()) {
            await this.clearCurrentUserContextAndEndRegistrationProcess(undefined, authenticationOptions);
            this.triggerEvent<RegistrationCanceledEvent>(AuthenticationEvents.REGISTRATION_CANCELED_EVENT, {
                originatorUrl: authenticationOptions?.originatorUrl,
                reason: 'sign-out'
            });
        }
        try {
            await this.showProgress(AuthenticationProgressIndicatorRole.SIGN_OUT);
            this.beginSignOutProcess(authenticationOptions);
            const signOutResponse = await this.authenticationHandler.signOut(authenticationOptions);
            this.storeOptions({
                signInProcessIsExternal: !!this.signInProcessOptions?.externalProcess,
                registrationProcessIsExternal: !!this.registrationProcessOptions?.externalProcess,
                ...(signOutResponse?.options || {})
            });
            if (signOutResponse?.stopped) {
                await this.endSignOutProcess(undefined, authenticationOptions);
                return;
            }
            if (this.signOutProcessOptions?.deferredProcess) {
                return;
            }
            authenticationOptions = this.buildOptions(signOutResponse?.options);
            await this.processSignOut(signOutResponse, authenticationOptions, false);
        } catch (e) {
            console.error(e);
            await this.clearCurrentUserContextAndEndSignOutProcess(e, authenticationOptions);
        }
    }

    public async register(authenticationOptions?: AuthenticationOptions): Promise<void> {
        authenticationOptions = this.getOptions(authenticationOptions);
        if (!this.isAuthenticationSupported() ||
            this.isAnyProcessInProgress()
        ) {
            return;
        }
        try {
            if (this.registrationProcessOptions?.externalProcess) {
                await this.showProgress(AuthenticationProgressIndicatorRole.REGISTER);
            }
            this.beginRegistrationProcess(authenticationOptions);
            const registrationResponse = await this.authenticationHandler.register(authenticationOptions);
            this.storeOptions({
                signInProcessIsExternal: !!this.signInProcessOptions?.externalProcess,
                registrationProcessIsExternal: !!this.registrationProcessOptions?.externalProcess,
                ...(registrationResponse?.options || {})
            });
            if (registrationResponse?.stopped) {
                await this.endRegistrationProcess(undefined, authenticationOptions);
                return;
            }
            await this.processUpdatedUserClaims(this.currentUserContext.toUserContext(), AuthenticationStateEnum.REGISTERING);
            if (this.registrationProcessOptions?.deferredProcess) {
                if (!this.registrationProcessOptions?.externalProcess && this.registrationProcessOptions?.disableNavigationWhileProcessInProgress) {
                    this.allowNavigation = false;
                }
                return;
            }
            authenticationOptions = this.buildOptions(registrationResponse?.options);
            await this.processRegistration(registrationResponse, authenticationOptions);
        } catch (e) {
            console.error(e);
            await this.clearCurrentUserContextAndEndRegistrationProcess(e, authenticationOptions);
        }
    }

    public async completeRegistration(data?: any, authenticationOptions?: AuthenticationOptions): Promise<void> {
        if (!this.isAuthenticationSupported() ||
            !this.isRegistrationSupported() ||
            !this.isRegistrationProcessInProgress() ||
            this.registrationProcessOptions?.externalProcess
        ) {
            return;
        }
        authenticationOptions = this.getOptions(authenticationOptions);
        try {
            const registrationResponse = await this.authenticationHandler.processRegistrationFlow(authenticationOptions, data);
            authenticationOptions = this.buildOptions(registrationResponse?.options);
            await this.processRegistration(registrationResponse, authenticationOptions);
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    public async cancelRegistration(authenticationOptions?: AuthenticationOptions): Promise<void> {
        if (!this.isAuthenticationSupported() ||
            !this.isRegistrationSupported() ||
            !this.isRegistrationProcessInProgress() ||
            this.registrationProcessOptions?.externalProcess
        ) {
            return;
        }
        authenticationOptions = this.getOptions(authenticationOptions);
        try {
            if (this.registrationProcessOptions?.authenticatedAtCancellationOfProcess) {
                await this.processUpdatedUserClaims(this.currentUserContext, AuthenticationStateEnum.AUTHENTICATED);
                await this.endRegistrationProcess(undefined, authenticationOptions);
            } else {
                await this.clearCurrentUserContextAndEndRegistrationProcess(undefined, authenticationOptions, true);
            }
            this.triggerEvent<RegistrationCanceledEvent>(AuthenticationEvents.REGISTRATION_CANCELED_EVENT, {
                originatorUrl: authenticationOptions?.originatorUrl,
                reason: 'user'
            });
            if (this.registrationProcessOptions?.redirectToOriginatorUrlOnProcessCancel && authenticationOptions?.originatorUrl) {
                authenticationOptions.redirectUrl = authenticationOptions?.originatorUrl;
            }
            this.processRedirects(authenticationOptions);
        } catch (e) {
            console.error(e);
            await this.clearCurrentUserContextAndEndRegistrationProcess(e, authenticationOptions);
            throw e;
        }
    }

    public onSignedIn(context: any, subscriberName: string, callback: SignedInCallback): IEventSubscription {
        return this.eventBus.registerBroadcast<SignedInEvent>(this, subscriberName, AuthenticationEvents.SIGNED_IN_EVENT, (event) => {
            callback?.call(context, event?.data?.userContext);
        });
    }

    public onSignedInCanceled(context: any, subscriberName: string, callback: SignedInCanceledCallback): IEventSubscription {
        return this.eventBus.registerBroadcast<SignedInCanceledEvent>(this, subscriberName, AuthenticationEvents.SIGNED_IN_CANCELED_EVENT, (event) => {
            callback?.call(context, event?.data);
        });
    }

    public onSignedInFailed(context: any, subscriberName: string, callback: SignedInFailedCallback): IEventSubscription {
        return this.eventBus.registerBroadcast<SignedInFailedEvent>(this, subscriberName, AuthenticationEvents.SIGNED_IN_FAILED_EVENT, (event) => {
            callback?.call(context, event?.data);
        });
    }

    public onSignedOut(context: any, subscriberName: string, callback: SignedOutCallback): IEventSubscription {
        return this.eventBus.registerBroadcast<SignedInEvent>(this, subscriberName, AuthenticationEvents.SIGNED_OUT_EVENT, (event) => {
            callback?.call(context, event?.data?.userContext);
        });
    }

    public onSignedOutFailed(context: any, subscriberName: string, callback: SignedOutFailedCallback): IEventSubscription {
        return this.eventBus.registerBroadcast<SignedOutFailedEvent>(this, subscriberName, AuthenticationEvents.SIGNED_OUT_FAILED_EVENT, (event) => {
            callback?.call(context, event?.data);
        });
    }

    public onRegistered(context: any, subscriberName: string, callback: RegisteredCallback): IEventSubscription {
        return this.eventBus.registerBroadcast<RegisteredEvent>(this, subscriberName, AuthenticationEvents.REGISTERED_EVENT, (event) => {
            callback?.call(context, event?.data?.userContext);
        });
    }

    public onRegistrationCanceled(context: any, subscriberName: string, callback: RegistrationCanceledCallback): IEventSubscription {
        return this.eventBus.registerBroadcast<RegistrationCanceledEvent>(this, subscriberName, AuthenticationEvents.REGISTRATION_CANCELED_EVENT, (event) => {
            callback?.call(context, event?.data);
        });
    }

    public onRegistrationFailed(context: any, subscriberName: string, callback: RegistrationFailedCallback): IEventSubscription {
        return this.eventBus.registerBroadcast<RegistrationFailedEvent>(this, subscriberName, AuthenticationEvents.REGISTRATION_FAILED_EVENT, (event) => {
            callback?.call(context, event?.data);
        });
    }

    public onUserContextChanged(context: any, subscriberName: string, callback: UserContextChangedCallback): IEventSubscription {
        return this.eventBus.registerBroadcast<UserContextChangedEvent>(this, subscriberName, AuthenticationEvents.USER_CONTEXT_CHANGED_EVENT, (event) => {
            callback?.call(context, event?.data?.userContext);
        });
    }

    public onUserContextInvalidated(context: any, subscriberName: string, callback: UserContextInvalidatedCallback): IEventSubscription {
        return this.eventBus.registerBroadcast<UserContextInvalidatedEvent>(this, subscriberName, AuthenticationEvents.USER_CONTEXT_INVALIDATED_EVENT, (event) => {
            callback?.call(context, event?.data?.userContext);
        });
    }

    public onUserSessionExpired(context: any, subscriberName: string, callback: UserSessionExpiredCallback): IEventSubscription {
        return this.eventBus.registerBroadcast<SessionExpiredEvent>(this, subscriberName, AuthenticationEvents.USER_SESSION_EXPIRED_EVENT, (event) => {
            callback?.call(context, event?.data);
        });
    }

    public async syncUserContext(): Promise<void> {
        const userContext = this.currentUserContext.clone();

        if (this.isAnyProcessInProgress() || !userContext.isAuthenticated()) {
            return;
        }

        try {
            const securityContext = this.getSecurityContext()
            if (!securityContext) {
                const sessionResponse = await this.sessionLifecycleManager.renewSession();
                if (ObjectUtility.isDefined(sessionResponse) &&
                    !sessionResponse.invalid &&
                    sessionResponse.profileId === sessionResponse.profileId &&
                    sessionResponse?.claims
                ) {
                    await this.processUpdatedUserClaims(sessionResponse, userContext.authenticationState);
                }
            } else {
                const sessionResponse = (await this.authenticationHandler.validateSecurityContext(userContext)) as SessionResponse;
                if (sessionResponse?.claims) {
                    await this.processUpdatedUserClaims(sessionResponse, userContext.authenticationState);
                }
            }
        } catch (e) {
            this.triggerEvent<UserContextInvalidatedEvent>(AuthenticationEvents.USER_CONTEXT_INVALIDATED_EVENT, {userContext: userContext});

            await this.clearCurrentUserContext(userContext, true);
        }
    }

    public getUniqueId(): string {
        return AuthenticationServiceTypeName;
    }

    public async onBeforeNavigate(url?: string): Promise<void> {
        const route = this.window.location.pathname;
        this.previousUrl = route;
        if (!RoutingUtilities.routeEqual(route, this.signInProcessOptions?.signInRoute, true) &&
            !RoutingUtilities.routeEqual(route, this.registrationProcessOptions?.registerRoute, true)) {
            this.previousOriginUrl = route;
            this.window.sessionStorage.setItem(AuthenticationServiceBase.ORIGINATOR_ROUTE, this.previousOriginUrl);
        } else {
            this.previousOriginUrl = this.window.sessionStorage.getItem(AuthenticationServiceBase.ORIGINATOR_ROUTE);
        }
    }

    public async onAfterNavigate(url?: string): Promise<void> {
        if (this.isAuthenticationSupported()) {
            if (!RoutingUtilities.routeEqual(this.previousOriginUrl, url, true)) {
                await this.syncUserContext();
            }
            if (this.isSignInProcessInProgress() &&
                !this.signInProcessOptions?.externalProcess &&
                this.signInProcessOptions?.signInRoute?.startsWith('/') &&
                RoutingUtilities.routeEqual(this.previousUrl, this.signInProcessOptions?.signInRoute, true)
            ) {
                if (!RoutingUtilities.routeStartsWith(url, this.signInProcessOptions?.signInRoute)) {
                    if (this.signInProcessOptions?.guardSignInRoute) {
                        this.window.history.replaceState(null, null, this.signInProcessOptions.signInRoute);
                    } else if (!this.signInProcessOptions?.disableNavigationWhileProcessInProgress &&
                        this.signInProcessOptions?.cancelProcessOnNavigateAway) {
                        const authenticationOptions = this.getOptions();
                        await this.clearCurrentUserContextAndEndSignInProcessOnNavigateAway(undefined, authenticationOptions);
                    }
                    return;
                }
            }
            if (this.isRegistrationSupported() &&
                this.isRegistrationProcessInProgress() &&
                !this.registrationProcessOptions?.externalProcess &&
                this.registrationProcessOptions?.registerRoute?.startsWith('/') &&
                RoutingUtilities.routeEqual(this.previousUrl, this.registrationProcessOptions?.registerRoute, true)
            ) {
                if (!RoutingUtilities.routeStartsWith(url, this.registrationProcessOptions?.registerRoute)) {
                    if (this.registrationProcessOptions?.guardRegistrationRoute) {
                        this.window.history.replaceState(null, null, this.registrationProcessOptions.registerRoute);
                    } else if (!this.registrationProcessOptions?.disableNavigationWhileProcessInProgress &&
                        this.registrationProcessOptions?.cancelProcessOnNavigateAway) {
                        const authenticationOptions = this.getOptions();
                        await this.clearCurrentUserContextAndEndRegistrationProcessOnNavigateAway(undefined, authenticationOptions);
                    }
                    return;
                }
            }
            if (this.isRegistrationSupported() &&
                !this.isRegistrationProcessInProgress() &&
                !this.registrationProcessOptions?.externalProcess &&
                this.registrationProcessOptions?.registerRoute?.startsWith('/') &&
                RoutingUtilities.routeEqual(this.previousUrl, this.registrationProcessOptions?.registerRoute, true) &&
                this.registrationProcessOptions?.guardRegistrationRoute
            ) {
                this.redirectToTenantDefaultRoute(this.previousOriginUrl);
                return;
            }
            if (!this.isSignInProcessInProgress() &&
                !this.signInProcessOptions?.externalProcess &&
                this.signInProcessOptions?.signInRoute?.startsWith('/') &&
                RoutingUtilities.routeEqual(this.previousUrl, this.signInProcessOptions?.signInRoute, true) &&
                this.signInProcessOptions?.guardSignInRoute
            ) {
                this.redirectToTenantDefaultRoute(this.previousOriginUrl);
                return;
            }
        }
    }

    public isNavigationAllowed(url?: string, hardNavigation?: boolean): boolean {
        if (!this.isAuthenticationSupported()) {
            return true;
        }
        if (!this.isRegistrationSupported() ||
            !this.isRegistrationProcessInProgress() ||
            !this.registrationProcessOptions?.disableNavigationWhileProcessInProgress ||
            this.registrationProcessOptions?.externalProcess ||
            !this.registrationProcessOptions?.registerRoute?.startsWith('/') ||
            (!hardNavigation && RoutingUtilities.routeStartsWith(url, this.registrationProcessOptions?.registerRoute))
        ) {
            return true;
        }
        if (!this.isSignInProcessInProgress() ||
            !this.signInProcessOptions?.disableNavigationWhileProcessInProgress ||
            this.signInProcessOptions?.externalProcess ||
            !this.signInProcessOptions?.signInRoute?.startsWith('/') ||
            (!hardNavigation && RoutingUtilities.routeStartsWith(url, this.signInProcessOptions?.signInRoute))
        ) {
            return true;
        }
        return this.allowNavigation;
    }

    public async onNavigationAllowed(): Promise<void> {
    }

    public async onNavigationBlocked(): Promise<void> {
    }

    protected bind(): void {
        if (!this.isAuthenticationSupported()) {
            return;
        }

        this.userContextHolder.onUserContextUpdated(this, async (userInfo: UserInfo) => {
            await this.processUpdatedUserInfo(userInfo);
        });

        this.tenantStore.onItemEvicted(this, AuthenticationServiceBase.SUBSCRIBER_NAME, async (key) => {
            if (key === AuthenticationConstants.SIGN_IN_PROCESS_FLAG_KEY) {
                await this.signInProcessExpired();
                return;
            }

            if (key === AuthenticationConstants.SIGN_OUT_PROCESS_FLAG_KEY) {
                await this.signOutProcessExpired();
                return;
            }

            if (key === AuthenticationConstants.REGISTRATION_PROCESS_FLAG_KEY) {
                await this.registrationProcessExpired();
                return;
            }
        });

        this.eventBus.registerBroadcast<SignInEvent>(this, AuthenticationServiceBase.SUBSCRIBER_NAME, AuthenticationEvents.SIGN_IN_EVENT, async (event) => {
            await this.signIn(event?.data?.options);
        });

        this.eventBus.registerBroadcast<SignOutEvent>(this, AuthenticationServiceBase.SUBSCRIBER_NAME, AuthenticationEvents.SIGN_OUT_EVENT, async (event) => {
            await this.signOut(event?.data?.options);
        });

        this.eventBus.registerBroadcast<RegisterEvent>(this, AuthenticationServiceBase.SUBSCRIBER_NAME, AuthenticationEvents.REGISTER_EVENT, async (event) => {
            await this.register(event?.data?.options);
        });

        this.eventBus.registerBroadcast<SessionExpiredEvent>(this, AuthenticationServiceBase.SUBSCRIBER_NAME, AuthenticationEvents.USER_SESSION_EXPIRED_EVENT, async (event) => {
            if (event.data?.broadcasterId === AuthenticationServiceBase.BROADCASTER_ID) {
                await this.processSessionExpired();
            } else {
                this.currentUserContext.reset();
            }
        });

        this.eventBus.registerBroadcast<UserContextChangedEvent>(this, AuthenticationServiceBase.SUBSCRIBER_NAME, AuthenticationEvents.USER_CONTEXT_CHANGED_EVENT, async (event) => {
            if (event.data?.broadcasterId === AuthenticationServiceBase.BROADCASTER_ID) {
                return;
            }
            if (event.data?.userContext?.authenticationState == AuthenticationStateEnum.UNAUTHENTICATED) {
                this.currentUserContext.reset();
            } else {
                this.loadCurrentUserContext();
            }
        });
    }

    protected beginSignInProcess(authenticationOptions?: ExtendedAuthenticationOptions): void {
        authenticationOptions = {
            redirectUrl: authenticationOptions?.redirectUrl || `${this.window.location.pathname}${this.window.location.search}`,
            removableQueryParameters: authenticationOptions?.removableQueryParameters || []
        };
        this.tenantStore.set(AuthenticationConstants.AUTHENTICATION_PROCESS_OPTIONS_KEY, {
            ...authenticationOptions
        } as ExtendedAuthenticationOptions);
        this.tenantStore.set(AuthenticationConstants.SIGN_IN_PROCESS_FLAG_KEY, true, {
            evictIn: (this.signInProcessOptions?.processTimeout && this.signInProcessOptions.processTimeout > 0) ? this.signInProcessOptions.processTimeout : AuthenticationConstants.DEFAULT_SIGN_IN_PROCESS_TIMEOUT
        });
    }

    protected async processSignIn(signInResponse?: SignInResponse, authenticationOptions?: ExtendedAuthenticationOptions, silentSignIn: boolean = false): Promise<void> {
        authenticationOptions = this.getOptions(authenticationOptions);
        const previousUserContext = this.currentUserContext.clone();
        const userContext = this.buildUserContextFromAuthenticationResponse(signInResponse);
        if (!userContext) {
            throw new Error('Could not build user context from given authentication response');
        }
        this.currentUserContext.update(userContext, false);
        this.storeCurrentUserContext();
        await this.endSignInProcess(undefined, authenticationOptions);
        const shouldDispatchUserContextChanged = !this.currentUserContext.equals(previousUserContext);
        if (shouldDispatchUserContextChanged) {
            this.triggerCrossTabEvent(AuthenticationEvents.USER_CONTEXT_CHANGED_EVENT, {
                userContext: this.getUserContext()
            } as UserContextChangedEvent);
        }
        if (previousUserContext.authenticationState == AuthenticationStateEnum.UNAUTHENTICATED && this.currentUserContext.authenticationState == AuthenticationStateEnum.AUTHENTICATED) {
            this.triggerCrossTabEvent(AuthenticationEvents.SIGNED_IN_EVENT, {
                userContext: this.getUserContext()
            } as SignedInEvent)
        }
        if (signInResponse.registrationRequired) {
            if (this.isRegistrationSupported()) {
                await this.register(authenticationOptions);
                return;
            } else {
                console.warn('Please configure registration process options in custom authentication handler.');
            }
        }
        if (this.signInProcessOptions?.redirectToOriginatorUrlOnProcessCompletion && authenticationOptions?.originatorUrl) {
            authenticationOptions.redirectUrl = authenticationOptions?.originatorUrl;
        }
        if (!silentSignIn) {
            this.processRedirects(authenticationOptions);
        }
    }

    protected async endSignInProcess(error?: any, authenticationOptions?: ExtendedAuthenticationOptions): Promise<void> {
        authenticationOptions = this.getOptions(authenticationOptions);
        this.tenantStore.remove(AuthenticationConstants.AUTHENTICATION_PROCESS_OPTIONS_KEY);
        this.tenantStore.remove(AuthenticationConstants.SIGN_IN_PROCESS_FLAG_KEY);
        if (!this.signInProcessOptions?.externalProcess && this.signInProcessOptions?.disableNavigationWhileProcessInProgress) {
            this.allowNavigation = true;
        }
        if (error) {
            this.triggerEvent<SignedInFailedEvent>(AuthenticationEvents.SIGNED_IN_FAILED_EVENT, {
                originatorUrl: authenticationOptions?.originatorUrl
            });
        }
        await this.hideProgress(AuthenticationProgressIndicatorRole.SIGN_IN);
    }

    protected beginSignOutProcess(authenticationOptions?: ExtendedAuthenticationOptions): void {
        authenticationOptions = {
            redirectUrl: authenticationOptions?.redirectUrl || `${this.window.location.pathname}${this.window.location.search}`,
            removableQueryParameters: authenticationOptions?.removableQueryParameters || []
        };
        this.tenantStore.set(AuthenticationConstants.AUTHENTICATION_PROCESS_OPTIONS_KEY, {
            ...authenticationOptions
        } as ExtendedAuthenticationOptions);
        this.tenantStore.set(AuthenticationConstants.SIGN_OUT_PROCESS_FLAG_KEY, true, {
            evictIn: (this.signOutProcessOptions?.processTimeout && this.signOutProcessOptions.processTimeout > 0) ? this.signOutProcessOptions.processTimeout : AuthenticationConstants.DEFAULT_SIGN_OUT_PROCESS_TIMEOUT
        });
    }

    protected async processSignOut(signOutResponse: SignOutResponse, authenticationOptions?: ExtendedAuthenticationOptions, showProgress: boolean = true): Promise<void> {
        if (showProgress) {
            await this.showProgress(AuthenticationProgressIndicatorRole.SIGN_OUT);
        }
        authenticationOptions = this.getOptions(authenticationOptions);
        if (this.isRegistrationProcessInProgress()) {
            await this.clearCurrentUserContextAndEndRegistrationProcess(undefined, authenticationOptions);
            return;
        }
        const previousUserContext = this.currentUserContext.clone();
        await this.clearCurrentUserContext(previousUserContext);
        await this.endSignOutProcess(undefined, authenticationOptions);
        if (this.signOutProcessOptions?.redirectToOriginatorUrlOnProcessCompletion && authenticationOptions?.originatorUrl) {
            authenticationOptions.redirectUrl = authenticationOptions?.originatorUrl;
        }
        this.processRedirects(authenticationOptions);
    }

    protected async endSignOutProcess(error?: any, authenticationOptions?: ExtendedAuthenticationOptions): Promise<void> {
        authenticationOptions = this.getOptions(authenticationOptions);
        this.tenantStore.remove(AuthenticationConstants.AUTHENTICATION_PROCESS_OPTIONS_KEY);
        this.tenantStore.remove(AuthenticationConstants.SIGN_OUT_PROCESS_FLAG_KEY);
        if (error) {
            this.triggerEvent<SignedOutFailedEvent>(AuthenticationEvents.SIGNED_OUT_FAILED_EVENT, {
                originatorUrl: authenticationOptions?.originatorUrl
            });
        }
        await this.hideProgress(AuthenticationProgressIndicatorRole.SIGN_OUT);
    }

    protected beginRegistrationProcess(authenticationOptions?: ExtendedAuthenticationOptions): void {
        authenticationOptions = {
            redirectUrl: authenticationOptions?.redirectUrl || `${this.window.location.pathname}${this.window.location.search}`,
            removableQueryParameters: authenticationOptions?.removableQueryParameters || []
        };
        this.tenantStore.set(AuthenticationConstants.AUTHENTICATION_PROCESS_OPTIONS_KEY, {
            ...authenticationOptions
        } as ExtendedAuthenticationOptions);
        this.tenantStore.set(AuthenticationConstants.REGISTRATION_PROCESS_FLAG_KEY, true, {
            evictIn: (this.registrationProcessOptions?.processTimeout && this.registrationProcessOptions.processTimeout > 0) ? this.registrationProcessOptions.processTimeout : AuthenticationConstants.DEFAULT_REGISTRATION_PROCESS_TIMEOUT
        });
    }

    protected async processRegistration(registrationResponse?: RegistrationResponse, authenticationOptions?: ExtendedAuthenticationOptions): Promise<void> {
        authenticationOptions = this.getOptions(authenticationOptions);
        let userContext: UserContext;
        if (this.registrationProcessOptions?.authenticatedAtCompletionOfProcess) {
            userContext = this.buildUserContextFromAuthenticationResponse(registrationResponse);
        } else {
            userContext = CurrentUserContext.getUnauthenticatedContext();
        }
        if (!userContext) {
            throw new Error('Could not build user context from given registration response');
        }
        this.currentUserContext.update(userContext, false, this.registrationProcessOptions?.treatRegisteringStateAsAuthenticatedState);
        this.storeCurrentUserContext();
        await this.endRegistrationProcess(undefined, authenticationOptions);
        this.triggerCrossTabEvent(AuthenticationEvents.USER_CONTEXT_CHANGED_EVENT, {
            userContext: this.getUserContext()
        } as UserContextChangedEvent);
        this.triggerCrossTabEvent(AuthenticationEvents.REGISTERED_EVENT, {
            userContext: this.getUserContext()
        } as RegisteredEvent);
        if (this.registrationProcessOptions?.authenticatedAtCompletionOfProcess &&
            this.currentUserContext.authenticationState == AuthenticationStateEnum.AUTHENTICATED) {
            this.triggerCrossTabEvent(AuthenticationEvents.SIGNED_IN_EVENT, {
                userContext: this.getUserContext()
            } as SignedInEvent);
        }
        if (this.registrationProcessOptions?.redirectToOriginatorUrlOnProcessCompletion && authenticationOptions?.originatorUrl) {
            authenticationOptions.redirectUrl = authenticationOptions?.originatorUrl;
        }
        this.processRedirects(authenticationOptions);
    }

    protected async endRegistrationProcess(error?: any, authenticationOptions?: ExtendedAuthenticationOptions): Promise<void> {
        authenticationOptions = this.getOptions(authenticationOptions);
        this.tenantStore.remove(AuthenticationConstants.AUTHENTICATION_PROCESS_OPTIONS_KEY);
        this.tenantStore.remove(AuthenticationConstants.REGISTRATION_PROCESS_FLAG_KEY);
        if (!this.registrationProcessOptions?.externalProcess && this.registrationProcessOptions.disableNavigationWhileProcessInProgress) {
            this.allowNavigation = true;
        }
        if (error) {
            this.triggerEvent<RegistrationFailedEvent>(AuthenticationEvents.REGISTRATION_FAILED_EVENT, {
                originatorUrl: authenticationOptions?.originatorUrl
            });
        }
        await this.hideProgress(AuthenticationProgressIndicatorRole.REGISTER);
    }

    protected async processUpdatedUserClaims(authenticationClaims: AuthenticationClaims, proposedState?: AuthenticationStateEnum): Promise<void> {
        const previousUserContext = this.currentUserContext.clone();
        if (!previousUserContext.isRegistering() && !previousUserContext.isAuthenticated()) {
            return;
        }
        const mappedClaims = this.claimsMapper.map(
            authenticationClaims.claims,
            RequiredClaims.REQUIRED_CLAIMS,
            this.authenticationHandler,
            OptionalClaims.OPTIONAL_CLAIMS,
            this.authenticationHandler
        );
        const userContext = CurrentUserContext.buildFromClaims(mappedClaims, this.registrationProcessOptions?.treatRegisteringStateAsAuthenticatedState);
        if (proposedState) {
            userContext.authenticationState = proposedState;
        }
        this.currentUserContext.update(userContext, !proposedState, this.registrationProcessOptions?.treatRegisteringStateAsAuthenticatedState);
        this.storeCurrentUserContext();
        this.triggerCrossTabEvent(AuthenticationEvents.USER_CONTEXT_CHANGED_EVENT, {
            userContext: this.getUserContext()
        } as UserContextChangedEvent);
        if ((previousUserContext.authenticationState == AuthenticationStateEnum.UNAUTHENTICATED || previousUserContext.authenticationState == AuthenticationStateEnum.REGISTERING) &&
            this.currentUserContext.authenticationState == AuthenticationStateEnum.AUTHENTICATED) {
            this.triggerCrossTabEvent(AuthenticationEvents.SIGNED_IN_EVENT, {
                userContext: this.getUserContext()
            } as SignedInEvent)
        }
    }

    protected async processUpdatedUserInfo(userInfo: UserInfo): Promise<void> {
        const previousUserContext = this.currentUserContext.clone();
        if (previousUserContext.authenticationState !== AuthenticationStateEnum.AUTHENTICATED) {
            return;
        }
        const mappedClaims = this.claimsMapper.map(
            userInfo.claims,
            RequiredClaims.REQUIRED_CLAIMS,
            this.authenticationHandler,
            OptionalClaims.OPTIONAL_CLAIMS,
            this.authenticationHandler
        );
        const userContext = CurrentUserContext.buildFromClaims(mappedClaims, this.registrationProcessOptions?.treatRegisteringStateAsAuthenticatedState);
        userContext.principalName = userInfo.principalName || userContext.principalName;
        userContext.displayName = userInfo.displayName || userContext.displayName;
        userContext.firstName = userInfo.firstName || userContext.firstName;
        userContext.lastName = userInfo.lastName || userContext.lastName;
        this.currentUserContext.update(userContext, true, false);
        this.storeCurrentUserContext();
        this.triggerCrossTabEvent(AuthenticationEvents.USER_CONTEXT_CHANGED_EVENT, {
            userContext: this.getUserContext()
        } as UserContextChangedEvent);
    }

    protected buildUserContextFromAuthenticationResponse(authenticatorResponse?: AuthenticationResponse, proposedState?: AuthenticationStateEnum): UserContext | undefined {
        if (!authenticatorResponse) {
            throw new Error(`Handler did not provide a valid authentication response ()`);
        }
        const mappedClaims = this.claimsMapper.map(
            authenticatorResponse.claims,
            RequiredClaims.REQUIRED_CLAIMS,
            this.authenticationHandler,
            OptionalClaims.OPTIONAL_CLAIMS,
            this.authenticationHandler
        );
        if (!this.requiredClaimsValidator.validate(mappedClaims, RequiredClaims.REQUIRED_CLAIMS)) {
            throw new Error(`Handler did not provide a valid authentication response (missing one of required claims - [${RequiredClaims.REQUIRED_CLAIMS.join(', ')}])`);
        }
        return CurrentUserContext.buildFromClaimsAndAuthenticationResponse(
            mappedClaims,
            authenticatorResponse,
            this.registrationProcessOptions?.treatRegisteringStateAsAuthenticatedState,
            proposedState
        );
    }

    protected async signInProcessExpired(): Promise<void> {
        const authenticationOptions = this.getOptions();
        const previousUserContext = this.currentUserContext.clone();
        await this.clearCurrentUserContext(previousUserContext, false);
        await this.hideProgress(AuthenticationProgressIndicatorRole.SIGN_IN);
        this.triggerEvent<SignedInFailedEvent>(AuthenticationEvents.SIGNED_IN_FAILED_EVENT, {
            originatorUrl: authenticationOptions?.originatorUrl
        });
    }

    protected async signOutProcessExpired(): Promise<void> {
        const authenticationOptions = this.getOptions();
        const previousUserContext = this.currentUserContext.clone();
        await this.clearCurrentUserContext(previousUserContext, false);
        await this.hideProgress(AuthenticationProgressIndicatorRole.SIGN_OUT);
        this.triggerEvent<SignedOutFailedEvent>(AuthenticationEvents.SIGNED_OUT_FAILED_EVENT, {
            originatorUrl: authenticationOptions?.originatorUrl
        });
    }

    protected async registrationProcessExpired(): Promise<void> {
        const authenticationOptions = this.getOptions();
        const previousUserContext = this.currentUserContext.clone();
        await this.clearCurrentUserContext(previousUserContext, true);
        await this.hideProgress(AuthenticationProgressIndicatorRole.REGISTER);
        this.triggerEvent<RegistrationFailedEvent>(AuthenticationEvents.REGISTRATION_FAILED_EVENT, {
            originatorUrl: authenticationOptions?.originatorUrl
        });
    }

    protected async processSessionExpired(): Promise<void> {
        const previousUserContext = this.currentUserContext.clone();
        await this.clearCurrentUserContext(previousUserContext, true)
    }

    protected isSignInProcessInProgress(): boolean {
        return !!this.tenantStore.get<boolean>(AuthenticationConstants.SIGN_IN_PROCESS_FLAG_KEY);
    }

    protected isSignOutProcessInProgress(): boolean {
        return !!this.tenantStore.get<boolean>(AuthenticationConstants.SIGN_OUT_PROCESS_FLAG_KEY);
    }

    protected isRegistrationProcessInProgress(): boolean {
        return !!this.tenantStore.get<boolean>(AuthenticationConstants.REGISTRATION_PROCESS_FLAG_KEY);
    }

    protected isAnyProcessInProgress(): boolean {
        return this.isSignInProcessInProgress() || this.isSignOutProcessInProgress() || this.isRegistrationProcessInProgress();
    }

    protected shouldClearSignoutStatusOnSignIn(): boolean {
        return !!this.tenant.getContext().authentication?.options?.clearSignoutStatusOnSignIn;
    }

    protected getAuthenticationHandler(): IAuthenticationHandler | undefined {
        const authOptions = this.tenant.getContext()?.authentication;
        const providerCode = authOptions?.provider;
        let handler: IAuthenticationHandler | undefined;
        if (!providerCode) {
            const tempHandler = this.serviceCollection.resolve<IAuthenticationHandler>(IAuthenticationHandlerTypeName);
            if (!tempHandler?.getUniqueCode) { // Support Assist backwards compatibility with their current implementation
                handler = tempHandler;
            }
        } else {
            handler = this.serviceCollection
                .resolveAll<IAuthenticationHandler>(IAuthenticationHandlerTypeName)
                .find(h => h.getUniqueCode && h.getUniqueCode()?.toLowerCase() === providerCode.toLowerCase());
        }
        return handler;
    }

    protected getSessionLifecycleManager(): ISessionLifecycleManager | undefined {
        const authOptions = this.tenant.getContext()?.authentication;
        const providerCode = authOptions?.provider;
        let manager: ISessionLifecycleManager | undefined;
        if (!providerCode) {
            const tempManager = this.serviceCollection.resolve<ISessionLifecycleManager>(ISessionLifecycleManagerTypeName);
            if (!tempManager?.getUniqueCode) { // Support Assist backwards compatibility with their current implementation
                manager = tempManager;
            }
        } else {
            manager = this.serviceCollection
                .resolveAll<ISessionLifecycleManager>(ISessionLifecycleManagerTypeName)
                .find(h => h.getUniqueCode && h.getUniqueCode()?.toLowerCase() === providerCode.toLowerCase());
        }
        return manager;
    }

    protected async clearSecurityContext(): Promise<void> {
        try {
            await this.authenticationHandler.clearSecurityContext();
        } catch (e) {
            console.error(e);
        }
    }

    protected async clearCurrentUserContext(previousUserContext: UserContext, triggerEvents: boolean = true): Promise<void> {
        await this.clearSecurityContext();
        this.currentUserContext.reset();
        this.storeCurrentUserContext();
        const shouldDispatchUserContextChanged = !this.currentUserContext.equals(previousUserContext);
        const shouldDispatchSignOutEvent = previousUserContext.authenticationState == AuthenticationStateEnum.AUTHENTICATED;
        if (triggerEvents) {
            if (shouldDispatchUserContextChanged) {
                this.triggerCrossTabEvent<UserContextChangedEvent>(AuthenticationEvents.USER_CONTEXT_CHANGED_EVENT, {
                    broadcasterId: AuthenticationServiceBase.BROADCASTER_ID,
                    userContext: this.getUserContext()
                } as UserContextChangedEvent);
            }
            if (shouldDispatchSignOutEvent) {
                this.triggerCrossTabEvent<SignedOutEvent>(AuthenticationEvents.SIGNED_OUT_EVENT, {
                    broadcasterId: AuthenticationServiceBase.BROADCASTER_ID,
                    userContext: this.getUserContext()
                } as SignedOutEvent);
            }
        }
    }

    protected async clearCurrentUserContextAndEndSignInProcess(e?: Error, authenticationOptions?: ExtendedAuthenticationOptions, triggerEvents: boolean = true): Promise<void> {
        const previousUserContext = this.currentUserContext.clone();
        await this.clearCurrentUserContext(previousUserContext, triggerEvents);
        await this.endSignInProcess(e, authenticationOptions);
    }

    protected async clearCurrentUserContextAndEndSignInProcessOnNavigateAway(e?: Error, authenticationOptions?: ExtendedAuthenticationOptions, triggerEvents: boolean = true): Promise<void> {
        await this.clearCurrentUserContextAndEndSignInProcess(e, authenticationOptions, triggerEvents);
        this.triggerEvent<SignedInCanceledEvent>(AuthenticationEvents.SIGNED_IN_CANCELED_EVENT, {
            originatorUrl: authenticationOptions?.originatorUrl,
            reason: 'navigate-away'
        });
    }

    protected async clearCurrentUserContextAndEndSignOutProcess(e?: Error, authenticationOptions?: ExtendedAuthenticationOptions, triggerEvents: boolean = true): Promise<void> {
        const previousUserContext = this.currentUserContext.clone();
        await this.clearCurrentUserContext(previousUserContext, triggerEvents);
        await this.endSignOutProcess(e, authenticationOptions);
    }

    protected async clearCurrentUserContextAndEndRegistrationProcess(e?: Error, authenticationOptions?: ExtendedAuthenticationOptions, triggerEvents: boolean = true): Promise<void> {
        const previousUserContext = this.currentUserContext.clone();
        await this.clearCurrentUserContext(previousUserContext, triggerEvents);
        await this.endRegistrationProcess(e, authenticationOptions);
    }

    protected async clearCurrentUserContextAndEndRegistrationProcessOnNavigateAway(e?: Error, authenticationOptions?: ExtendedAuthenticationOptions, triggerEvents: boolean = true): Promise<void> {
        await this.clearCurrentUserContextAndEndRegistrationProcess(e, authenticationOptions, triggerEvents);
        this.triggerEvent<RegistrationCanceledEvent>(AuthenticationEvents.REGISTRATION_CANCELED_EVENT, {
            originatorUrl: authenticationOptions?.originatorUrl,
            reason: 'navigate-away'
        });
    }

    protected loadCurrentUserContext(): void {
        let currentUserContext: CurrentUserContext;
        try {
            currentUserContext = this.tenantStore.get<CurrentUserContext>(AuthenticationConstants.CURRENT_USER_CONTEXT_STORE_KEY) ?? CurrentUserContext.getUnauthenticatedContext();
        } catch (e) {
            currentUserContext = CurrentUserContext.getUnauthenticatedContext();
        }
        Object.assign(this.currentUserContext, currentUserContext);
    }

    protected storeCurrentUserContext() {
        this.tenantStore.set(AuthenticationConstants.CURRENT_USER_CONTEXT_STORE_KEY, this.currentUserContext);
    }

    protected triggerCrossTabEvent<TType extends BroadcasterEvent>(eventType: string, event: TType, crossTabEvent: boolean = true): void {
        event.broadcasterId = AuthenticationServiceBase.BROADCASTER_ID;
        return this.eventBus.dispatchBroadcast<TType>(AuthenticationServiceBase.SUBSCRIBER_NAME, eventType, event, undefined, crossTabEvent);
    }

    protected triggerEvent<TType>(eventType: string, event?: TType): void {
        return this.eventBus.dispatchBroadcast<TType>(AuthenticationServiceBase.SUBSCRIBER_NAME, eventType, event);
    }

    protected buildOptions(authenticationOptions?: ExtendedAuthenticationOptions): ExtendedAuthenticationOptions {
        const localOptions = {
            ...(authenticationOptions || {})
        } as ExtendedAuthenticationOptions;
        localOptions.originatorUrl = localOptions.originatorUrl || `${this.window.location.pathname}${this.window.location.search}`;
        localOptions.redirectUrl = localOptions.redirectUrl || authenticationOptions?.redirectUrl;
        localOptions.removableQueryParameters = localOptions.removableQueryParameters || authenticationOptions?.removableQueryParameters;
        return localOptions;
    }

    protected getOptions(authenticationOptions?: ExtendedAuthenticationOptions): ExtendedAuthenticationOptions | undefined {
        authenticationOptions = authenticationOptions || this.tenantStore.get<ExtendedAuthenticationOptions>(AuthenticationConstants.AUTHENTICATION_PROCESS_OPTIONS_KEY);
        return this.buildOptions(authenticationOptions);
    }

    protected storeOptions(authenticationOptions?: ExtendedAuthenticationOptions): void {
        this.tenantStore.set(AuthenticationConstants.AUTHENTICATION_PROCESS_OPTIONS_KEY, authenticationOptions);
    }

    protected processRedirects(authenticationOptions?: ExtendedAuthenticationOptions): void {
        authenticationOptions.redirectUrl = authenticationOptions?.redirectUrl || authenticationOptions?.originatorUrl;
        if (authenticationOptions?.redirectUrl) {
            LocationUtils.performRedirect(authenticationOptions?.redirectUrl, this.window);
            return;
        }
        if (authenticationOptions?.removableQueryParameters) {
            LocationUtils.removeQueryParametersAndReplaceState(authenticationOptions.removableQueryParameters, this.window);
            return;
        }
    }

    protected redirectToTenantDefaultRoute(originatorRoute?: string): void {
        this.window.history.replaceState(null, null, originatorRoute || this.tenant.getContext()?.site?.routing?.default || '/');
    }

    protected async showProgress(role: AuthenticationProgressIndicatorRole, localizationCode?: string): Promise<void> {
        const config = this.progressReportingOptionsMap[role];
        if (!config.enabled) {
            return;
        }

        if (this.hideProgressTimeoutHandler) {
            clearTimeout(this.hideProgressTimeoutHandler);
            this.hideProgressTimeoutHandler = undefined;
            await this.hideProgress(role);
        }

        try {
            if (this.visibleProgressIndicator) {
                this.visibleProgressIndicator.localizationCode = localizationCode;
                await this.progressIndicatorsService.update(this, this.visibleProgressIndicator);
            } else {
                this.visibleProgressIndicator = await this.progressIndicatorsService.show(this, {
                    owner: AuthenticationServiceTypeName,
                    localizationCode: localizationCode || config?.localizationCode || '',
                    blocking: true,
                    deterministic: false,
                    onTranslationsRequested: this.onTranslationsRequested
                });
            }
        } catch (e) {
            this.visibleProgressIndicator = undefined;
            // Suppress error, we do not need it in console
            // console.debug(e);
        }
    }

    protected async hideProgress(role: AuthenticationProgressIndicatorRole): Promise<void> {
        const config = this.progressReportingOptionsMap[role];
        if (!config.enabled) {
            return;
        }
        if (!this.hideProgressTimeoutHandler) {
            this.hideProgressTimeoutHandler = setTimeout(async () => {
                try {
                    clearTimeout(this.hideProgressTimeoutHandler);

                    if (this.visibleProgressIndicator) {
                        await this.progressIndicatorsService.hide(this.visibleProgressIndicator);
                    }

                    this.hideProgressTimeoutHandler = undefined;
                    this.visibleProgressIndicator = undefined;
                } catch (e) {
                    console.error(e);
                }
            }, 150);
        }
    }

    protected async onTranslationsRequested(): Promise<Record<string, any>> {
        return await this.translationService.getTranslationsBySupportedLocales();
    }

    protected buildProgressReportingOptions(options?: AuthenticationProgressReportingOptions): ProgressReportingOptions {
        return {
            enabled: ObjectUtility.isDefined(options?.enabled) ? options?.enabled : false,
            silentSignInEnabled: ObjectUtility.isDefined(options?.silentSignInEnabled) ? options?.silentSignInEnabled : true,
            silentSignInLocalizationCode: AuthenticationConstants.PROGRESS_INDICATORS_SILENT_SIGN_IN_LOCALIZATION_CODE,
            signInEnabled: ObjectUtility.isDefined(options?.signInEnabled) ? options?.signInEnabled : true,
            signInLocalizationCode: AuthenticationConstants.PROGRESS_INDICATORS_SIGN_IN_LOCALIZATION_CODE,
            signOutEnabled: ObjectUtility.isDefined(options?.signOutEnabled) ? options?.signOutEnabled : true,
            signOutLocalizationCode: AuthenticationConstants.PROGRESS_INDICATORS_SIGN_OUT_LOCALIZATION_CODE,
            registerEnabled: ObjectUtility.isDefined(options?.registerEnabled) ? options?.registerEnabled : true,
            registerLocalizationCode: AuthenticationConstants.PROGRESS_INDICATORS_REGISTERING_LOCALIZATION_CODE,
            localizedResources: options?.localizedResources
        }
    }

    protected buildProgressReportingOptionsMap(options: ProgressReportingOptions): Record<string, ProgressReportingRoleOptions> {
        const map: Record<string, ProgressReportingRoleOptions> = {};
        map[AuthenticationProgressIndicatorRole.SILENT_SIGN_IN] = {
            enabled: options.enabled && options.silentSignInEnabled,
            localizationCode: options.silentSignInLocalizationCode
        };
        map[AuthenticationProgressIndicatorRole.SIGN_IN] = {
            enabled: options.enabled && options.signInEnabled,
            localizationCode: options.signInLocalizationCode
        };
        map[AuthenticationProgressIndicatorRole.SIGN_OUT] = {
            enabled: options.enabled && options.signOutEnabled,
            localizationCode: options.signOutLocalizationCode
        };
        map[AuthenticationProgressIndicatorRole.REGISTER] = {
            enabled: options.enabled && options.registerEnabled,
            localizationCode: options.registerLocalizationCode
        };
        return map;
    }
}
